<template>
  <div class="pay-result">
    <!-- 头部 -->
    <van-nav-bar title="支付确认"/>
    <!-- 内容 -->
    <ul>
      <li class="pay-img">
        <img src="./payresult.png" alt />
      </li>
      <li class="pay-txt">是否已完成支付？</li>
      <li class="pay-btns">
        <wy-btn @click.native="paySuccess">支付完成</wy-btn>
        <wy-btn @click.native="payCancel" >未支付</wy-btn>
      </li>
    </ul>
  </div>
</template>

<script>
import { NavBar } from "vant";
import Button from "../common/btn";
export default {
  components: {
    [NavBar.name]: NavBar,
    wyBtn: Button
  },
  methods: {
    onClickLeft() {
      this.$router.back(0);
    },
    // 支付完成
    paySuccess() {
      this.$router.push({
        path: "/myorder",
        query: {
          active: "2"
        }
      });
    },
    // 支付失败
    payCancel() {
      this.$router.push({
        path: "/myorder",
        query: {
          active: "1"
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/element.less";
@red: #c33a3a;
@black: #323232;
.pay-result {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  height: 100vh;
  .van-nav-bar {
    box-shadow: 0px 2px 4px #efefef;
    .van-icon {
      color: #ccc;
    }
  }
  & > ul {
    width: 100%;
    box-sizing: border-box;
    padding: .96rem /* 48/50 */;
    text-align: center;
    .pay-img {
      img {
        width: 50%;
      }
    }
    .pay-txt {
      margin: .64rem /* 32/50 */ 0;
      color: #6a6767;
      font-size: .32rem /* 16/50 */
    }
    .pay-btns {
      .list(row);
      justify-content: space-between;
      .btn {
        width: 46%;
        .btnmin(@padding:.18rem /* 9/50 */ 0rem; @radius:.06rem /* 3.2/50 */; @size:.32rem /* 16/50 */;);
        &:first-child {
          border: 1px solid @red;
          color: @red;
        }
        &:last-child {
          border: 1px solid @black;
          color: @black;
        }
      }
    }
  }
}
</style>